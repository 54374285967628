// src/stripe/StripeProvider.jsx

import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Replace with your Stripe publishable key
const stripePromise = loadStripe(
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
);

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;