// src/pages/SignUp.jsx
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCheckmarkCircle,
} from "react-icons/io";
import {
  signup as signupService,

} from "../../utils/apis/authService";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

import { useDispatch } from "react-redux";
import InputField from "../common/InputField"; // Adjust the path if necessary
import { toast } from "react-toastify";
import { signup } from "../../redux/slices/authSlice";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});

  // Password requirements state
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const [isPasswordSpecialCharValid, setIsPasswordSpecialCharValid] =
    useState(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);

  // Password visibility state
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordUppercaseValid, setIsPasswordUppercaseValid] =
    useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string()
    .min(3)
    .max(50)
    .regex(/^[A-Za-z].*$/) // Ensures the first character is a letter
    .required()
    .messages({
      "string.empty": "Name is required",
      "string.min": "Name must be at least 3 characters long",
      "string.max": "Name cannot exceed 50 characters",
      "string.pattern.base": "Name must start with a letter and contain alphabetic characters",
    }),  
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.email": "Invalid email format",
        "string.empty": "Email is required",
      }),
    password: Joi.string()
      .min(8)
      .pattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])"))
      .required()
      .messages({
        "string.min": "Password must be at least 8 characters long",
        "string.pattern.base":
          "Password must contain at least one uppercase letter, one lowercase letter, and one special character",
        "string.empty": "Password is required",
      }),
    confirmPassword: Joi.any().valid(Joi.ref("password")).required().messages({
      "any.only": "Confirm Password does not match Password",
      "any.required": "Confirm Password is required",
    }),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate password requirements in real-time
  useEffect(() => {
    const { password, confirmPassword } = formData;
    setIsPasswordLengthValid(password.length >= 8);
    setIsPasswordSpecialCharValid(/[!@#$%^&*]/.test(password));
    setDoPasswordsMatch(password === confirmPassword && confirmPassword !== "");
  }, [formData.password, formData.confirmPassword, formData]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const [isSubmitted, setIsSubmitted] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Clear previous errors
    setIsSubmitted(true);
  
    // Frontend Validation
    const { error } = schema.validate(formData, { abortEarly: false });
  
    if (error) {
      const validationErrors = {};
      error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
      setIsSubmitted(false);
      return;
    }
  
    try {
      const data = await signupService(
        formData.name,
        formData.email,
        formData.password
      );
  
      toast.success("Signup successful!");
      dispatch(
        signup({
          name: formData.name,
          email: formData.email,
          password: formData.password,
        })
      );
  
      if (data) {
        navigate("/thank-you");
      }
    } catch (error) {
      let validationErrors = {};
  
      // Check if the error response contains validation errors
      if (error?.status === "fail" && error?.errors?.length > 0) {
        console.log("error : ", error )
        error?.errors.forEach((err) => {
          validationErrors[err.field] = err.message; // Assign error messages to respective fields
        });
  
        setErrors(validationErrors); // Set errors to be displayed under respective fields
      } else {
        toast.error(error?.message);
      }
    } finally {
      setIsSubmitted(false);
    }
  };
  
  
  
  useEffect(() => {
    const password = formData.password;
    const confirmPassword = formData.confirmPassword;

    setIsPasswordLengthValid(password.length >= 8);

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    setIsPasswordSpecialCharValid(specialCharRegex.test(password));

    const uppercaseRegex = /[A-Z]/;
    setIsPasswordUppercaseValid(uppercaseRegex.test(password));

    setDoPasswordsMatch(password === confirmPassword);
  }, [formData.password, formData.confirmPassword]);

  return (
    <div className="flex font-inter items-center justify-center bg-white">

      <div className="flex flex-col items-center justify-center bg-white max-w-lg w-[393px] mx-auto">
        {/* Heading */}
        <h2 className="font-inter text-[32px] font-semibold text-center text-black mb-6">
          Create an account
        </h2>

        {/* Sign Up Form */}
        <form onSubmit={handleSubmit} className="w-[393px] space-y-4">
          {/* Name Field */}
          <InputField
            label="Name"
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            error={errors.name}
            ariaDescribedBy="name-error"
          />

          {/* Email Field */}
          <InputField
            label="Email"
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            ariaDescribedBy="email-error"
          />

          {/* Password Field */}
          <InputField
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            placeholder="Enter your password"
            value={formData.password}
            onChange={handleChange}
            error={errors.password}
            ariaDescribedBy="password-error"
            additionalElements={
              <button
                type="button"
                className="flex items-center text-gray-600 focus:outline-none"
                onClick={togglePasswordVisibility}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? (
                  <IoEyeOffOutline size={20} color="black" />
                ) : (
                  <IoEyeOutline size={20} color="black" />
                )}
              </button>
            }
          />

          {/* Confirm Password Field */}
          <InputField
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm your password"
            value={formData.confirmPassword}
            onChange={handleChange}
            error={errors.confirmPassword}
            ariaDescribedBy="confirmPassword-error"
            additionalElements={
              <button
                type="button"
                className="flex items-center text-gray-600 focus:outline-none"
                onClick={toggleConfirmPasswordVisibility}
                aria-label={
                  showConfirmPassword ? "Hide password" : "Show password"
                }
              >
                {showConfirmPassword ? (
                  <IoEyeOffOutline size={20} color="black" />
                ) : (
                  <IoEyeOutline size={20} color="black" />
                )}
              </button>
            }
          />

          {/* Password Requirements */}
          <div className="font-inter text-[#475467] text-[14px] space-y-2">
            <div className="flex items-center">
              {isPasswordLengthValid ? (
                <IoIosCheckmarkCircle size={22} color="green" />
              ) : (
                <IoIosCheckmarkCircleOutline size={22} color="gray" />
              )}
              <span
                className={`ml-2 font-inter text-[#475467] text-[14px] ${isPasswordLengthValid ? "text-black" : "text-gray-600"
                  }`}
              >
                At least 8 characters
              </span>
            </div>
            <div className="flex items-center">
              {isPasswordSpecialCharValid ? (
                <IoIosCheckmarkCircle size={22} color="green" />
              ) : (
                <IoIosCheckmarkCircleOutline size={22} color="gray" />
              )}
              <span
                className={`ml-2 font-inter text-[#475467] text-[14px] ${isPasswordSpecialCharValid ? "text-black" : "text-gray-600"
                  }`}
              >
                Must contain a special character
              </span>
            </div>
            <div className="flex items-center">
              {isPasswordUppercaseValid ? (
                <IoIosCheckmarkCircle size={22} color="green" />
              ) : (
                <IoIosCheckmarkCircleOutline size={22} color="gray" />
              )}
              <span
                className={`ml-2 font-inter text-[#475467] text-[14px] ${isPasswordUppercaseValid ? "text-black" : "text-gray-600"
                  }`}
              >
                Must contain at least one upper case letter
              </span>
            </div>
            <div className="flex items-center">
              {doPasswordsMatch ? (
                <IoIosCheckmarkCircle size={22} color="green" />
              ) : (
                <IoIosCheckmarkCircleOutline size={22} color="gray" />
              )}
              <span
                className={`ml-2 font-inter text-[#475467] text-[14px] ${doPasswordsMatch ? "text-black" : "text-gray-600"
                  }`}
              >
                Passwords match
              </span>
            </div>
          </div>

          {/* Sign Up Button */}
          <button
            type="submit"
            disabled={isSubmitted}
            className={`w-full h-11 text-white rounded-[12px] focus:outline-none ${isSubmitted
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-[#003EDD] hover:bg-blue-700 transition-colors"
              }`}
          >
            {isSubmitted ? "Signing Up..." : "Create Account"}
          </button>

        </form>

        {/* Login Option */}
        <p className="mt-4 text-[16px] font-inter font-normal text-[#60768E] text-center">
          Already have an account?{" "}
          <Link
            to="/login"
            className="text-[#003EDD] font-interfont-semibold hover:underline"
          >
            Log in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
