import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi";
import {
    IoIosCheckmarkCircleOutline,
    IoIosCheckmarkCircle
} from "react-icons/io";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { resetPassword } from "../../utils/apis/authService";
import InputField from "../common/InputField"; // Adjust the path if necessary
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
    const { token } = useParams();

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);


    // Password requirements state
    const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
    const [isPasswordSpecialCharValid, setIsPasswordSpecialCharValid] = useState(false);
    const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);

    // Password visibility state
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isPasswordUppercaseValid, setIsPasswordUppercaseValid] = useState(false);


    const schema = Joi.object({
        password: Joi.string()
            .min(8)
            .pattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])"))
            .required()
            .messages({
                "string.min": "Password must be at least 8 characters long",
                "string.pattern.base":
                    "Password must contain at least one uppercase letter, one lowercase letter, and one special character",
                "string.empty": "Password is required",
            })
        ,
        confirmPassword: Joi
            .any()
            .valid(Joi.ref("password"))
            .required().messages({
                "any.only": "Confirm Password does not match Password",
                "any.required": "Confirm Password is required",
            }),
    });

    // Validate password requirements in real-time
    useEffect(() => {
        const { password, confirmPassword } = formData;
        setIsPasswordLengthValid(password.length >= 8);
        setIsPasswordSpecialCharValid(/[!@#$%^&*]/.test(password));
        setDoPasswordsMatch(password === confirmPassword && confirmPassword !== "");
    }, [formData]);

    useEffect(() => {
        const password = formData.password;
        const confirmPassword = formData.confirmPassword;

        setIsPasswordLengthValid(password.length >= 8);

        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        setIsPasswordSpecialCharValid(specialCharRegex.test(password));

        const uppercaseRegex = /[A-Z]/;
        setIsPasswordUppercaseValid(uppercaseRegex.test(password));

        setDoPasswordsMatch(password === confirmPassword);
    }, [formData]);


    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        // Frontend Validation
        const { error } = schema.validate(formData, { abortEarly: false });

        if (error) {
            const validationErrors = {};
            error.details.forEach((err) => {
                validationErrors[err.path[0]] = err.message;
            });
            setErrors(validationErrors);
            setIsSubmitted(false);
            return;
        }

        try {
            const data = await resetPassword(
                token,
                formData.password,
                formData.confirmPassword
            );
            toast.success("Password is Reset successfully!"); // Show success message

            if (data) {
                navigate("/login");
            }
            return data;
        } catch (error) {
            let validationErrors = {};

            // Check if the error response contains validation errors
            if (error?.status === "fail" && error?.errors?.length > 0) {
                console.log("error : ", error)
                error?.errors.forEach((err) => {
                    validationErrors[err.field] = err.message; // Assign error messages to respective fields
                });

                setErrors(validationErrors); // Set errors to be displayed under respective fields
            } else {
                toast.error(error?.message);
            }
        } finally {
            setIsSubmitted(false);
        }
    };


    return (
        <div className="font-inter flex flex-col items-center justify-center p-4 bg-white max-w-lg mx-auto sm:p-3">
            {/* Logo */}
            <img
                src="/assets/images/Logo/Logo.png"
                alt="Logo"
                className="max-h-full w-auto"
            />

            {/* Main Content Container */}
            <div
                className="font-inter flex flex-col items-center justify-center gap-8 mt-8"
            // Removed inline style for better Tailwind integration
            >
                {/* Key Icon */}
                <img
                    src="/assets/images/Logo/keyIcon.svg"
                    alt="Key Icon"
                    className="w-[56px] h-[56px] opacity-100"
                />

                {/* Heading */}
                <h2 className="font-inter text-[32px] font-semibold text-center text-black">
                    Reset password
                </h2>

                {/* Reset Password Form */}
                <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">

                    {/* Password Field */}
                    <InputField
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        value={formData.password}
                        onChange={handleChange}
                        error={errors.password}
                        ariaDescribedBy="password-error"
                        additionalElements={
                            <button
                                type="button"
                                className="flex items-center text-gray-600 focus:outline-none"
                                onClick={togglePasswordVisibility}
                                aria-label={showPassword ? "Hide password" : "Show password"}
                            >
                                {showPassword ? (
                                    <IoEyeOffOutline size={20} color="black" />
                                ) : (
                                    <IoEyeOutline size={20} color="black" />
                                )}
                            </button>
                        }
                    />

                    {/* Confirm Password Field */}
                    <InputField
                        label="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm your password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        error={errors.confirmPassword}
                        ariaDescribedBy="confirmPassword-error"
                        additionalElements={
                            <button
                                type="button"
                                className="flex items-center text-gray-600 focus:outline-none"
                                onClick={toggleConfirmPasswordVisibility}
                                aria-label={
                                    showConfirmPassword ? "Hide password" : "Show password"
                                }
                            >
                                {showConfirmPassword ? (
                                    <IoEyeOffOutline size={20} color="black" />
                                ) : (
                                    <IoEyeOutline size={20} color="black" />
                                )}
                            </button>
                        }
                    />

                    {/* Password Requirements */}
                    <div className="font-inter text-[#475467] text-[14px] space-y-2">
                        <div className="flex items-center">
                            {isPasswordLengthValid ? (
                                <IoIosCheckmarkCircle size={22} color="green" />
                            ) : (
                                <IoIosCheckmarkCircleOutline size={22} color="gray" />
                            )}
                            <span
                                className={`ml-2 font-inter text-[#475467] text-[14px] ${isPasswordLengthValid ? "text-black" : "text-gray-600"
                                    }`}
                            >
                                At least 8 characters
                            </span>
                        </div>
                        <div className="flex items-center">
                            {isPasswordSpecialCharValid ? (
                                <IoIosCheckmarkCircle size={22} color="green" />
                            ) : (
                                <IoIosCheckmarkCircleOutline size={22} color="gray" />
                            )}
                            <span
                                className={`ml-2 font-inter text-[#475467] text-[14px] ${isPasswordSpecialCharValid ? "text-black" : "text-gray-600"
                                    }`}
                            >
                                Must contain a special character
                            </span>
                        </div>
                        <div className="flex items-center">
                            {isPasswordUppercaseValid ? (
                                <IoIosCheckmarkCircle size={22} color="green" />
                            ) : (
                                <IoIosCheckmarkCircleOutline size={22} color="gray" />
                            )}
                            <span
                                className={`ml-2 font-inter text-[#475467] text-[14px] ${isPasswordUppercaseValid ? "text-black" : "text-gray-600"
                                    }`}
                            >
                                Must contain at least one upper case letter
                            </span>
                        </div>
                        <div className="flex items-center">
                            {doPasswordsMatch ? (
                                <IoIosCheckmarkCircle size={22} color="green" />
                            ) : (
                                <IoIosCheckmarkCircleOutline size={22} color="gray" />
                            )}
                            <span
                                className={`ml-2 font-inter text-[#475467] text-[14px] ${doPasswordsMatch ? "text-black" : "text-gray-600"
                                    }`}
                            >
                                Passwords match
                            </span>
                        </div>
                    </div>


                    {/* Submit Button */}
                    <button
                        type="submit"
                        className={`w-full px-4 py-3 text-white rounded-md focus:outline-none ${isSubmitted
                            ? "bg-[#003EDD] cursor-not-allowed"
                            : "bg-[#003EDD] hover:bg-blue-700 transition-colors"
                            }`}
                        disabled={isSubmitted}
                    >
                        {isSubmitted ? "Reseting..." : "Reset password"}
                    </button>
                </form>

                {/* Back to Login Link */}
                <div className="text-center">
                    <Link to="/login" className="text-[#475467] hover:underline">
                        ← Back to log in
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
