import api from "./api";

// Create a new subscription
export const createSubscription = async (planId, paymentMethodId) => {
  try {
    console.log("planId, paymentMethodId:,", planId, paymentMethodId)
    const response = await api.post("payment/subscriptions/create", { planId, paymentMethodId });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Create a portal session (no need to pass customerId; backend uses req.user)
export const createPortalSession = async () => {
  try {
    const response = await api.post("payment/subscriptions/portal");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
