// redux/slices/authSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  login as loginService,
  signup as signupService,
  logout as logoutService,
} from "../../utils/apis/authService";

import { getMe as getMeService } from "../../utils/apis/userService";

// Define initial state
const initialState = {
  user: null,
  loading: false,
  error: null,
};

// Async thunk for login
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    console.log("email:", email, "password:", password)
    try {
      const data = await loginService(email, password);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Async thunk for signup
export const signup = createAsyncThunk(
  "auth/signup",
  async ({ name, email, password }, { rejectWithValue }) => {
    try {
      const data = await signupService(name, email, password);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Async thunk for fetching user data
export const fetchUser = createAsyncThunk(
  "auth/fetchUser",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getMeService();
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch user data"
      );
    }
  }
);

// Async thunk for logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      await logoutService();
      return;
    } catch (error) {
      return rejectWithValue(error.message || "Logout failed");
    }
  }
);

// Create the slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Synchronous reducers if needed
    setUser(state, action) {
      state.user = action.payload;
    },
    clearAuth(state) {
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.data.user; // Corrected line
      // Tokens are handled via HTTP-only cookies; no need to store in state
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Login failed";
    });

    // Signup
    builder.addCase(signup.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.loading = false;
      // Optionally, handle signup success (e.g., redirect to login)
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Signup failed";
    });

    // Fetch User
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.data.user; // Corrected line
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to fetch user";
      state.user = null;
      // Optionally, handle token refresh or prompt re-login
    });

    // Logout
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
      state.error = null;
      state.initialized = true; // Keep initialized as true to prevent re-fetch
    });

    builder.addCase(logout.rejected, (state, action) => {
      state.error = action.payload?.message || "Logout failed";
    });
  },
});

// Export actions
export const { setUser, clearAuth } = authSlice.actions;

// Export reducer
export default authSlice.reducer;
