import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FaCheck } from "react-icons/fa6";
import { createSubscription } from "../utils/apis/subscriptionService";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
);


const features = [
  "Unlimited Condition Infographic Creation",
  "Unlimited Size Infographic Creation",
  "Access to Over 100 Clothing Measurement Templates",
  "Text Description Generator",
  "Cancel Anytime",
];

const CheckoutForm = ({ onPaymentMethodCreated, isSubmitting }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: "Customer Name", // Replace with actual customer name
        },
      });

      if (error) {
        console.error("Error creating payment method:", error);
        setError(error.message);
      } else {

        // Call the parent function to handle subscription creation
        if (onPaymentMethodCreated) {
          onPaymentMethodCreated(paymentMethod.id); // Pass paymentMethod.id to the parent component
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#6B7280",
        fontSize: "16px",
        fontFamily: "Arial, sans-serif",
        "::placeholder": {
          color: "#A0AEC0",
        },
      },
      invalid: {
        color: "#E53E3E",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-gray-100 p-4 rounded-md shadow-md">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-4 rounded-md shadow-lg disabled:opacity-50"
      >
        Start Free Trial
      </button>
    </form>
  );
};

function CheckoutPage({ planId }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();

  const handleSubscription = async (paymentMethodId) => {
    setIsSubmitting(true); // Show the spinner
    setError(null);

    try {

      const sub = await createSubscription(
        planId, // Pass the selected plan ID
        paymentMethodId // Pass the payment method ID
      );

      toast.success(sub.message);

      // Reload the page after fetching the user data
      setIsSubmitting(false); // Hide the spinner
      navigate("/history-timeline");
    } catch (err) {
      console.error("Error creating subscription:", err);
      setError(err.error.message || "Failed to create subscription");
      setIsSubmitting(false); // Hide the spinner
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column - Subscription Details */}
            <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
              <h2 className="text-2xl font-semibold text-indigo-700">One plan, one price. Simple.</h2>
              <div className="mt-6 space-y-6">
                <div className="text-lg font-semibold text-indigo-700">Standard Subscription - $25.00 / month</div>
                <div className="text-indigo-700">
                  <h3 className="text-xl font-semibold">Start Your Journey with a 7-Day Free Trial</h3>
                  <p className="mt-2 text-gray-600">Sign up today and enjoy 7 days of full access to all Illuscriber features.</p>
                </div>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="text-lg font-semibold mb-4 text-indigo-700">Standard Subscription Features</h3>
                  <ul className="space-y-3">
                    {features.map((feature, index) => (
                      <li key={index} className="flex items-start">
                        <FaCheck className="h-5 w-5 mr-2 text-indigo-700" />
                        <span className="text-sm text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Right Column - Payment Details */}
            <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
              <h2 className="text-2xl font-semibold text-indigo-700">Payment Details</h2>
              <p className="text-gray-600 mt-2">Enter your payment details below to activate your free trial.</p>
              <CheckoutForm onPaymentMethodCreated={handleSubscription} isSubmitting={isSubmitting} />
              {error && <p className="text-red-500 text-sm mt-4">{error}</p>}

              {/* Spinner */}
              {isSubmitting && (
                <div className="flex justify-center mt-6">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
                </div>
              )}

              <div className="mt-6">
                <p className="text-lg font-medium">Billed today: $0.00</p>
              </div>

              <div className="text-sm text-gray-600 space-y-2 mt-6">
                <ul className="list-disc list-outside ml-5">
                  <li>Cancel your subscription anytime</li>
                  <li>After the trial ends, you will be charged $25.00 monthly until you cancel your subscription</li>
                </ul>
              </div>
            </div>
          </div>

          {/* FAQ Section */}
          <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
            <h2 className="text-2xl font-semibold text-indigo-700 mb-6">Frequently Asked Questions</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold text-gray-900">Do I need to enter my credit card details?</h3>
                <p className="mt-2 text-gray-600">To start your free trial and continue with a subscription later, a debit or credit card is required.</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">What payment methods are accepted?</h3>
                <p className="mt-2 text-gray-600">We accept all major credit and debit cards. Illuscriber utilizes Stripe, a trusted and secure payment gateway, to process transactions.</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">Cancel anytime.</h3>
                <p className="mt-2 text-gray-600">If you decide Illuscriber isn't for you, you can cancel your subscription at any time—no hassle, no questions asked.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Elements>
  );
}

export default CheckoutPage;
