import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "react-toastify";
import { IoIosCheckmarkCircle, IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../redux/slices/authSlice";
import { updateMe, updateMeasurementSettings } from "../../utils/apis/userService";
import { updatePassword, toggleAccountStatus } from "../../utils/apis/authService";

const profileFormSchema = z.object({
  name: z.string().min(3, {
    message: "Name must be at least 3 characters.",
  }),
});

const passwordFormSchema = z.object({
  oldPassword: z.string().min(8, {
    message: "Password must be at least 8 characters.",
  }),
  newPassword: z
    .string()
    .min(8, {
      message: "Password must be at least 8 characters.",
    })
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, {
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, and one special character.",
    }),
  confirmPassword: z.string(),
}).refine((data) => data.newPassword === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

export default function AccountInfo() {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.auth);
  const [isProcessing, setIsProcessing] = useState(false);

  // Load user data when component mounts
  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [user, dispatch]);

  // Conditionally initialize the form after the user data is fetched
  const profileForm = useForm({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      name: user?.name || "",
    },
  });

  // Update the form values whenever the user data changes
  useEffect(() => {
    if (user) {
      profileForm.reset({
        name: user.name || "",
      });
    }
  }, [user, profileForm]);

  // Password change form state
  const passwordForm = useForm({
    resolver: zodResolver(passwordFormSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  // Size Tool Settings state - Using the new schema fields
  const [selectedUnit, setSelectedUnit] = useState("INCHE");
  const [showBoth, setShowBoth] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // Initialize size settings from user data
  useEffect(() => {
    if (user) {
      // With the new schema, we can directly use the separate fields
      setSelectedUnit(user.defualtSizemeasuringUnits || "INCHE");
      setShowBoth(user.showBothMeasurementUnits || false);
    }
  }, [user]);

  // Handle the unit selection change
  const handleUnitChange = async (unit) => {
    setSelectedUnit(unit);
    await updateUnitSettings(unit, showBoth);
  };

  // Handle the showBoth toggle
  const handleShowBothChange = async (checked) => {
    setShowBoth(checked);
    await updateUnitSettings(selectedUnit, checked);
  };

  // Function to update unit settings on the backend
  const updateUnitSettings = async (unit, showBothFlag) => {
    setIsUpdating(true);

    try {
      // Send both parameters to the updated API
      await updateMeasurementSettings(unit, showBothFlag);
      await dispatch(fetchUser()).unwrap();
      toast.success("Measurement settings updated successfully!");
    } catch (error) {
      toast.error("Failed to update measurement settings. Please try again.");
      console.error("Error updating settings:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle profile form submission to update profile
  const onProfileSubmit = async (data) => {
    const { name } = data;
    try {
      const result = await updateMe(name);
      toast.success(result.message || "Profile updated successfully!");
      dispatch(fetchUser());
    } catch (error) {
      toast.error(error.message || "Failed to update profile.");
    }
  };

  // Handle password change form submission
  const onPasswordSubmit = async (data) => {
    const { oldPassword, newPassword } = data;

    try {
      const result = await updatePassword(oldPassword, newPassword);
      toast.success(result.message || "Password updated successfully!");
      passwordForm.reset();
    } catch (error) {
      toast.error(error.message || "Failed to update password.");
    }
  };

  // Toggle Account Status (Deactivate/Reactivate)
  const handleToggleAccountStatus = async () => {
    setIsProcessing(true);
    try {
      const response = await toggleAccountStatus(); // Call backend API
      toast.success(response.message);
      dispatch(fetchUser()); // Refresh user state
    } catch (error) {
      toast.error(error.message || "Failed to update account status.");
    } finally {
      setIsProcessing(false);
    }
  };


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="space-y-8">
      {/* First Row: Profile Update & Change Password */}
      <div className="flex space-x-8">
        {/* Profile Update Section */}
        <div className="flex-1 p-12 bg-white shadow rounded-md">
          <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Update Profile</h2>
          <p className="text-gray-600 mb-6">Update your account information here.</p>

          {/* Render form only after user data is available */}
          {user && (
            <form onSubmit={profileForm.handleSubmit(onProfileSubmit)} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-gray-700 mb-2">Name</label>
                <input
                  type="text"
                  id="name"
                  {...profileForm.register("name")}
                  className={`w-full px-3 py-2 border ${profileForm.formState.errors.name ? "border-red-500" : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
                />
                {profileForm.formState.errors.name && (
                  <p className="text-red-500 text-sm mt-1">{profileForm.formState.errors.name.message}</p>
                )}
              </div>

              <button
                type="submit"
                className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4] mt-2"
              >
                Update Profile
              </button>
            </form>
          )}
        </div>

        {/* Password Change Section */}
        <div className="flex-1 p-12 bg-white shadow rounded-md">
          <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Change Password</h2>

          {/* Password criteria */}
          <div className="font-inter text-[#475467] text-[14px] space-y-2 mb-6">
            <div className="flex items-center">
              {passwordForm.watch("newPassword")?.length >= 8 ? (
                <IoIosCheckmarkCircle size={22} color="green" />
              ) : (
                <IoIosCheckmarkCircleOutline size={22} color="gray" />
              )}
              <span className={`ml-2 ${passwordForm.watch("newPassword")?.length >= 8 ? "text-black" : "text-gray-600"
                }`}>
                At least 8 characters
              </span>
            </div>

            <div className="flex items-center">
              {/[!@#$%^&*]/.test(passwordForm.watch("newPassword") || '') ? (
                <IoIosCheckmarkCircle size={22} color="green" />
              ) : (
                <IoIosCheckmarkCircleOutline size={22} color="gray" />
              )}
              <span className={`ml-2 ${/[!@#$%^&*]/.test(passwordForm.watch("newPassword") || '') ? "text-black" : "text-gray-600"
                }`}>
                Must contain a special character
              </span>
            </div>

            <div className="flex items-center">
              {/[A-Z]/.test(passwordForm.watch("newPassword") || '') ? (
                <IoIosCheckmarkCircle size={22} color="green" />
              ) : (
                <IoIosCheckmarkCircleOutline size={22} color="gray" />
              )}
              <span className={`ml-2 ${/[A-Z]/.test(passwordForm.watch("newPassword") || '') ? "text-black" : "text-gray-600"
                }`}>
                Must contain at least one upper case letter
              </span>
            </div>

            <div className="flex items-center">
              {passwordForm.watch("newPassword") === passwordForm.watch("confirmPassword") &&
                passwordForm.watch("confirmPassword") ? (
                <IoIosCheckmarkCircle size={22} color="green" />
              ) : (
                <IoIosCheckmarkCircleOutline size={22} color="gray" />
              )}
              <span className={`ml-2 ${passwordForm.watch("newPassword") === passwordForm.watch("confirmPassword") &&
                passwordForm.watch("confirmPassword") ? "text-black" : "text-gray-600"
                }`}>
                Passwords match
              </span>
            </div>
          </div>

          <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)} className="space-y-4">
            <div>
              <label htmlFor="oldPassword" className="block text-gray-700 mb-2">Old Password</label>
              <input
                type="password"
                id="oldPassword"
                {...passwordForm.register("oldPassword")}
                className={`w-full px-3 py-2 border ${passwordForm.formState.errors.oldPassword ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
              />
              {passwordForm.formState.errors.oldPassword && (
                <p className="text-red-500 text-sm">{passwordForm.formState.errors.oldPassword.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="newPassword" className="block text-gray-700 mb-2">New Password</label>
              <input
                type="password"
                id="newPassword"
                {...passwordForm.register("newPassword")}
                className={`w-full px-3 py-2 border ${passwordForm.formState.errors.newPassword ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
              />
              {passwordForm.formState.errors.newPassword && (
                <p className="text-red-500 text-sm">{passwordForm.formState.errors.newPassword.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                {...passwordForm.register("confirmPassword")}
                className={`w-full px-3 py-2 border ${passwordForm.formState.errors.confirmPassword ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
              />
              {passwordForm.formState.errors.confirmPassword && (
                <p className="text-red-500 text-sm">{passwordForm.formState.errors.confirmPassword.message}</p>
              )}
            </div>

            <button
              type="submit"
              className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>

      {/* Second Row: Tool Settings - Redesigned with separated unit selection and show both */}
      <div className="p-12 bg-white shadow rounded-md">
        <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Tool Settings</h2>
        <p className="text-gray-600 mb-6">Choose your default unit of measurement</p>

        {/* Units section with separate radio group and checkbox */}
        <div className="space-y-6">
          {/* Default Unit Selection - Radio Group */}
          <div>
            <div className="flex space-x-8">
              {/* Inches Option */}
              <div className="flex items-center space-x-2">
                <input
                  type="radio"
                  id="INCHE"
                  name="unit"
                  value="INCHE"
                  checked={selectedUnit === "INCHE"}
                  onChange={() => handleUnitChange("INCHE")}
                  disabled={isUpdating}
                />
                <label htmlFor="INCHE" className="text-sm text-gray-700">
                  Inches
                </label>
              </div>

              {/* Centimeters Option */}
              <div className="flex items-center space-x-2">
                <input
                  type="radio"
                  id="CENTIMETER"
                  name="unit"
                  value="CENTIMETER"
                  checked={selectedUnit === "CENTIMETER"}
                  onChange={() => handleUnitChange("CENTIMETER")}
                  disabled={isUpdating}
                />
                <label htmlFor="CENTIMETER" className="text-sm text-gray-700">
                  Centimeters
                </label>
              </div>
            </div>
          </div>

          {/* Show Both Option - Separated Checkbox */}
          <div className="border-t pt-4">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="showBoth"
                checked={showBoth}
                onChange={(e) => handleShowBothChange(e.target.checked)}
                disabled={isUpdating}
              />
              <label htmlFor="showBoth" className="text-sm text-gray-700">
                Display both
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-8">
        {/* Account Settings Section */}
        <div className="p-12 bg-white shadow rounded-md">
          <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Account Settings</h2>
          <p className="mt-4 text-lg text-gray-600">
            Please cancel your subscription before deactivating your account.
            <br />
            <span className="text-sm text-gray-500">(Refresh the extension to apply changes.)</span>
          </p>
          <div className="mt-4">
            <button
              onClick={handleToggleAccountStatus}
              className={`px-4 py-2 rounded-md shadow-md text-white transition ${user?.active
                  ? "bg-red-600 hover:bg-red-700"
                  : "bg-green-600 hover:bg-green-700"
                } ${isProcessing ? "opacity-50 cursor-not-allowed" : ""}`}
              disabled={isProcessing}
            >
              {isProcessing
                ? "Processing..."
                : user?.active
                  ? "Deactivate Account"
                  : "Reactivate Account"}
            </button>
          </div>
        </div>
      </div>

    </div>
  );
}