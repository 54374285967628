// src/App.jsx

import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store"; // Ensure correct path
import StripeSettings from "./pages/sidebar/Subscription"; // Corrected import
// import HistoryTimeline from "./pages/sidebar/HistoryTimeline"; // Corrected import
import DashboardLayout from "./components/layout/DashboardLayout"; // Ensure correct path
// import ProtectedRoute from "./components/common/ProtectedRoute"; // Import ProtectedRoute
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SignUp from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import AfterSignUp from "./components/auth/AfterSignUp";
import AccountInfo from "./pages/sidebar/AccountInfo";
import StripeProvider from "./stripe/StripeProvider";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ResetConfirmation from "./components/auth/ResetConfirmation";

const App = () => {
  return (
    <StripeProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ToastContainer />
          <Routes>
            {/* Redirect root to login */}
            <Route path="/" element={<Navigate to="/login" replace />} />

            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/thank-you" element={<AfterSignUp />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-confirmation" element={<ResetConfirmation />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />

            {/* Protected Admin Routes with Layout */}
            {/* <Route element={<ProtectedRoute />}> */}
              <Route path="/dashboard" element={<DashboardLayout />}>
                {/* <Route path="history-timeline" element={<HistoryTimeline />} /> */}
                <Route path="subscription" element={<StripeSettings />} />
                <Route path="settings" element={<AccountInfo />} />
                {/* Add more admin routes here */}
                <Route path="*" element={<Navigate to="dashboard" replace />} />
              </Route>
            {/* </Route> */}

            {/* Catch-all Route */}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
    </StripeProvider>
  );
};

export default App;