import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi";
import { useDispatch, useSelector } from "react-redux";
import { login, fetchUser } from "../../redux/slices/authSlice";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { toast } from "react-toastify";

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });


  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // Password visibility state
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.email": "Please enter a valid email.",
        "string.empty": "Email is required.",
      }),
    password: Joi.string().required().messages({
      "string.empty": "Password is required.",
    }),
  });

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); 

    setSubmitted(true); // **Disable button AFTER aborting previous request**
  
    // Validate form inputs using Joi
    const { error } = schema.validate(loginData, { abortEarly: false });
  
    if (error) {
      const validationErrors = {};
      error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
      setSubmitted(false); // **Re-enable button if validation fails**
      return;
    }
  
    try {
      const result = await dispatch(
        login({
          email: loginData.email,
          password: loginData.password,
          source: "PORTAL",
        })
      ).unwrap();
  
      console.log("User logged in:", result);
      toast.success("Signed in successfully!");
      navigate("/dashboard/subscription");
    } catch (error) {
      let errorMessage = "Validation Error";
      if (error?.message?.includes("Validation Error")) {
        errorMessage =
          "The email or password you entered doesn't match our records. Please double-check your login details and try again.";
      } else if (error?.message) {
        errorMessage = error.message;
      }
      toast.error(errorMessage);
    } finally {
      setSubmitted(false); // **Re-enable button after request finishes**
    }
  };
  


  // Handle post-login actions
  useEffect(() => {
    if (user) {
      // Fetch additional user data
      dispatch(fetchUser());
      // Redirect to dashboard or desired page
      navigate("/dashboard/subscription");
    }
  }, [user, dispatch, navigate]);

  return (
    <div className="flex flex-col justify-between items-center bg-white px-4 sm:px-6 lg:px-8">
      {/* Top Section: Logo and Form */}
      <img
        src="/assets/images/Logo/Logo.png"
        alt="Logo"
        className="max-h-full w-auto"
      />
      <div className="flex flex-col items-center w-full max-w-md mt-6">
        <img
          src="/assets/images/Logo/sign_in 1.png"
          alt="Illustration"
          className="w-11/12 mb-4"
        />

        <form
          onSubmit={handleSubmit}
          className="flex flex-col space-y-4 w-full"
        >
          <h2 className="text-2xl font-semibold text-center text-black">
            Sign In
          </h2>

          {/* Email Field */}
          <div className="flex flex-col rounded-[12px] p-2">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              value={loginData.email}
              onChange={handleChange}
              className={`w-full h-11 bg-[#f6faff] placeholder:text-[#95B2D1] py-2.5 px-3.5 rounded-[12px] focus:outline-none`}
              // required
              aria-describedby="email-error"
            />
            {errors.email && (
              <p id="email-error" className="mt-1 text-sm text-red-500">
                {errors.email}
              </p>
            )}
          </div>

          {/* Password Field */}
          <div className="relative flex flex-col rounded-[12px] p-2">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Enter your password"
              value={loginData.password}
              onChange={handleChange}
              className={`w-full h-11 py-2.5 px-3.5 bg-[#f6faff] placeholder:text-[#95B2D1] rounded-[12px] focus:outline-none}`}
              // required
              aria-describedby="password-error"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
              onClick={togglePasswordVisibility}
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? (
                <IoEyeOffOutline size={20} color="black" />
              ) : (
                <IoEyeOutline size={20} color="black" />
              )}
            </button>
            {errors.password && (
              <p id="password-error" className="mt-1 text-sm text-red-500">
                {errors.password}
              </p>
            )}
          </div>

          {/* Forgot Password Link */}
          <div className="w-full text-right mt-2">
            <Link
              to="/forgot-password"
              className="text-[#60768E] text-sm hover:underline"
            >
              Forgot Password?
            </Link>
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className={`w-full px-4 py-3 text-white rounded-md focus:outline-none ${submitted
                  ? "bg-[#003EDD] cursor-not-allowed"
                  : "bg-[#003EDD] hover:bg-blue-700 transition-colors"
                }`}
              disabled={submitted}
            >
              {submitted ? "Signing In..." : "Sign In"}
            </button>
          </div>
        </form>
      </div>

      {/* Bottom Section: Sign-up Prompt */}
      <div className="w-full max-w-md mb-6">
        <p className="mt-4 text-[16px] text-center text-sm text-[#60768E]">
          Don't have an account?{" "}
          <Link
            to="/signup"
            className="text-[#003EDD] font-semibold hover:underline"
          >
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
