// src/services/authService.js
import api from "./api";

// Signup
export const signup = async (name, email, password) => {
  try {
    const response = await api.post("/auth/signup", { name, email, password });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Login
export const login = async (email, password) => {
  try {
    const response = await api.post("/auth/login", { email, password });
    // Store the token
     const { token } = response.data;
     localStorage.setItem("illuscriber_token", token);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Logout
export const logout = async () => {
  try {
    await api.get("/auth/logout");
  } catch (error) {
    throw error.response.data;
  }
};

// Forgot Password
export const forgotPassword = async (email) => {
  try {
    const response = await api.post("/auth/forgotPassword", { email });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Update password function
export const resetPassword = async (token, password, confirmPassword) => {
  try {
    const response = await api.patch(`/auth/resetPassword/${token}`, { password, confirmPassword });
    return response.data; // Success response with message or user data
  } catch (error) {
    throw error.response.data; // Throw the error message from the backend
  }
};


// Update password function
export const updatePassword = async (oldPassword, newPassword) => {
  try {
    const response = await api.patch('/auth/updatePassword', { oldPassword, newPassword });
    return response.data; // Success response with message or user data
  } catch (error) {
    throw error.response.data; // Throw the error message from the backend
  }
};

// Toggle account status (Activate or Deactivate)
export const toggleAccountStatus = async () => {
  try {
    const response = await api.patch("/auth/toggle-account-status");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};