import React from 'react';
import { Link } from 'react-router-dom';

const AfterSignUp = () => {
  return (
    <div className="min-h-screen flex flex-col items-center bg-white">
      {/* Logo Section */}
      <div className="w-full flex justify-center pt-4 px-6">
        <img
          src="/assets/images/Logo/Logo.png"
          alt="Logo"
          className="max-h-full w-auto max-w-[180px] sm:max-w-[200px]"
        />
      </div>

      {/* Main Content Section */}
      <div className="flex-grow flex items-center justify-center w-full max-w-4xl px-6 py-8">
        <div className="font-sans flex flex-col items-center justify-center gap-2 w-full max-w-lg">
          {/* Email Icon */}
          <img
            src="/assets/images/Logo/emailboxicon.svg"
            alt="Email Icon"
            className="w-[48px] h-[48px] sm:w-[56px] sm:h-[56px] opacity-100 mb-2"
          />

          <h2 className="font-sans text-[24px] sm:text-[32px] font-semibold text-center text-black mb-2">
            Thanks for signing up!
          </h2>
          
          <p className="font-sans text-[16px] sm:text-[18px] text-center text-[#60768E] mb-4 max-w-md">
            To finalize your registration, please check your inbox for a verification email we've just sent your way.
          </p>
          
          <p className="font-sans text-[14px] sm:text-[16px] text-center text-[#60768E] max-w-md">
            If you don't see the email within a few minutes, be sure to check your spam or junk folder—sometimes they like to hide there!
          </p>

          <div className="mt-6 text-center">
            <Link 
              to="/login" 
              className="text-[#475467] hover:underline text-[14px] sm:text-[16px] font-semibold"
            >
              ← Go to Log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfterSignUp;