import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutPage from "../../stripe/CheckoutForm";
import { fetchUser } from "../../redux/slices/authSlice";
import { createPortalSession } from "../../utils/apis/subscriptionService";
import { createSubscription } from "../../utils/apis/subscriptionService";
import { toast } from "react-toastify";

// Load Stripe instance
const stripePromise = loadStripe(
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
);

const Subscription = () => {
  const { user, loading } = useSelector((state) => state.auth);
  const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);
  const dispatch = useDispatch();

  // Extract subscription details
  const subscription = user?.subscription;
  const subscriptionStatus = subscription?.status || null;
  const currentPeriodEnd = subscription?.current_period_end
    ? new Date(subscription.current_period_end).toLocaleDateString()
    : null;

  // ✅ Determine correct subscription state
  const computedStatus =
    subscriptionStatus === "CANCELED"
      ? currentPeriodEnd
        ? "CANCELED_ACTIVE" // ✅ Can renew before period ends
        : "CANCELED_EXPIRED" // ✅ Subscription fully expired
      : subscriptionStatus;

  console.log("computedStatus : ",computedStatus)
  // ✅ Subscription Status Messages
  const statusMessages = {
    ACTIVE: {
      text: "Your subscription is active. Enjoy unlimited access!",
      bg: "bg-green-600/80 text-white",
    },
    CANCELED_ACTIVE: {
      text: "Your subscription has been canceled. You can renew until your period ends.",
      bg: "bg-yellow-600/80 text-gray-900",
    },
    CANCELED_EXPIRED: {
      text: "Your subscription has ended. Start a new subscription to continue.",
      bg: "bg-gray-600/80 text-white",
    },
    PAST_DUE: {
      text: "Your payment failed. Please update your payment method in portal.",
      bg: "bg-red-600/80 text-white",
    },
    INCOMPLETE: {
      text: "Your payment is processing. Click to see the latest status.",
      bg: "bg-orange-600/75 text-gray-900",
    },
  };

  // ✅ Handle User Refresh for Payment Status
  const handleRefresh = () => {
    dispatch(fetchUser());
  };


  // ✅ Handle Subscription Creation (For Canceled Users)
  const handleRenewSubscription = async () => {
    setIsCreatingSubscription(true);
    try {
      const sub = await createSubscription(process.env.REACT_APP_PLAN_ID, null);
      toast.warn(sub.message);
      dispatch(fetchUser());
    } catch (error) {
       toast.warn(error.message);
      console.error("❌ Error renewing subscription:", error);
    } finally {
      setIsCreatingSubscription(false);
    }
  };

  // ✅ Refresh subscription data on mount
  useEffect(() => {
    dispatch(fetchUser());
  }, [subscriptionStatus, dispatch]);

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
      </div>
    );
  }

  // ✅ Manage Subscription via Stripe Portal
  const handleManageSubscription = async () => {
    try {
      console.log("🛠 Opening Stripe customer portal...");
      const { url } = await createPortalSession();
      window.open(url, "_blank");
    } catch (error) {
      console.error("❌ Error creating portal session:", error);
    }
  };

  return (
    <div className="p-4">
      {/* ✅ Subscription Status Banner */}
      {computedStatus && statusMessages[computedStatus] && (
        <div className={`text-white px-6 py-1.5 text-left rounded ${statusMessages[computedStatus].bg}`}>
          <p className="text-base font-medium">{statusMessages[computedStatus].text}
            {" "}
            {/* ✅ Show "Refresh Status" Button for INCOMPLETE status */}
            {computedStatus === "INCOMPLETE" && (
              <span
                className="mt-2 text-white text-sm font-semibold underline cursor-pointer"
                onClick={handleRefresh}
              >
                Refresh Status
              </span>
            )}

            {computedStatus === "CANCELED_EXPIRED" && (
              <span
                className="mt-2 text-white text-sm font-semibold underline cursor-pointer"
                onClick={handleManageSubscription}
              >
                Manage Subscription in Portal
              </span>
            )}
          </p>
        </div>
      )}

      <h1 className="text-2xl font-semibold mt-6 mb-4">Subscription</h1>
      <p className="mb-6">Manage your subscription plans and billing here.</p>

      {/* ✅ Subscription Management Buttons */}
      {computedStatus === "ACTIVE" || computedStatus === "CANCELED_ACTIVE" || computedStatus === "PAST_DUE" ? (
        <div className="flex flex-col md:flex-row items-center gap-6 mt-6">
          <div className="w-full md:w-auto">
            <button
              className="w-full md:w-auto bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
              onClick={handleManageSubscription}
            >
              Manage Subscription in Portal
            </button>
          </div>
        </div>
      ) : computedStatus === "CANCELED_EXPIRED" ? (
        <button
          className="bg-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:bg-green-700 transition"
          onClick={handleRenewSubscription}
          disabled={isCreatingSubscription}
        >
          {isCreatingSubscription ? "Processing..." : "Renew Subscription"}
        </button>
      ) : !subscription ? (
        <div className="col-span-6 bg-white px-5">
          <Elements stripe={stripePromise}>
            <CheckoutPage planId={process.env.REACT_APP_PLAN_ID} />
          </Elements>
        </div>
      ) : null}
    </div>
  );
};

export default Subscription;