// src/api.js
import axios from "axios";

// Set API URL based on environment mode
const getBaseURL = () => {
  if (process.env.REACT_APP_MODE === "development") {
    return process.env.REACT_APP_API_BASE_DEV_URL; // Local development
  }
  return process.env.REACT_APP_API_BASE_PROD_URL; // Production or other environments
};

// Create an Axios instance
const api = axios.create({
  baseURL: getBaseURL(),
  withCredentials: true, // Include cookies if needed
});

// Request interceptor to add auth token to headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("illuscriber_token"); // Retrieve accessToken from localStorage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // You can handle global errors here
    return Promise.reject(error);
  }
);

export default api;
