import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { forgotPassword } from "../../utils/apis/authService";
import Joi from "joi";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // **Email Validation Schema**
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } }) // Ensures valid email format
      .required()
      .messages({
        "string.email": "Please enter a valid email address",
        "string.empty": "Email is required",
      }),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // **Client-side validation before making API call**
    const { error } = schema.validate({ email }, { abortEarly: false });

    if (error) {
      setError(error.details[0].message); // Show only the first validation error
      setLoading(false);
      return;
    }

    try {
      await forgotPassword(email);
      // Redirect to the "Check your email" screen with email as a query parameter
      navigate(`/reset-confirmation?email=${encodeURIComponent(email)}`);
    } catch (error) {

      // Check if the error response contains validation errors
      if (error?.status === "fail" && error?.errors?.length > 0) {
        console.log("error : ", error)
        const validationMessages = error.errors.map(err => err.message).join(" ");
        setError(validationMessages); // Set errors to be displayed under respective fields
      } else {
        console.log("Error : ", error)
        setError(error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="font-inter flex flex-col items-center justify-center p-4 bg-white max-w-lg mx-auto sm:p-3">
      {/* Logo */}
      <img
        src="/assets/images/Logo/Logo.png"
        alt="Logo"
        className="max-h-full w-auto"
      />

      {/* Main Content Container */}
      <div className="font-inter flex flex-col items-center justify-center gap-8 mt-8">
        {/* Key Icon */}
        <img
          src="/assets/images/Logo/keyIcon.svg"
          alt="Key Icon"
          className="w-[56px] h-[56px] opacity-100"
        />

        {/* Heading */}
        <h2 className="font-inter text-[32px] font-semibold text-center text-black">
          Forgot password?
        </h2>

        {/* Description */}
        <p className="font-inter font-normal text-[18px] text-center text-[#60768E]">
          No worries, we’ll send you reset instructions.
        </p>

        {/* Reset Password Form */}
        <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">
          {/* Email Field */}
          <div className="flex flex-col rounded-[12px]">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full h-11 bg-[#f6faff] placeholder:text-[#95B2D1] py-2.5 px-3.5 rounded-[12px] focus:outline-none ${error ? "border border-red-500" : ""
                }`}
              required
              aria-describedby="email-error"
            />
            {error && (
              <p id="email-error" className="mt-1 text-sm text-red-500">
                {error}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className={`w-full px-4 py-3 text-white rounded-md focus:outline-none ${loading
              ? "bg-[#003EDD] cursor-not-allowed"
              : "bg-[#003EDD] hover:bg-blue-700 transition-colors"
              }`}
            disabled={loading}
          >
            {loading ? "Sending..." : "Reset password"}
          </button>
        </form>

        {/* Back to Login Link */}
        <div className="text-center">
          <Link to="/login" className="text-[#475467] hover:underline">
            ← Back to log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
